import DefaultInfo from "./_default";
import FIELD_TYPES from "../fieldTypes";
import {exerciseWithTime} from "./common/exercise";
import {answerWithCorrectIncorrectFeedback} from "./common/answer";


class ParachutesInfo extends DefaultInfo {
  static exercise = {
    parameters: [
      ...exerciseWithTime,
      {
        id: "fallSpeed",
        type: FIELD_TYPES.radio,
        description: "Prędkość spadania",
        default: 0,
        values: {
          "fast": "Szybko",
          "medium": "Średnio",
          "slow": "Wolno",
        },
        inline: true,
        small: false,
      }
    ],
    modules: []
  };

  static question = {
    ...DefaultInfo.question,
    hasContent: false,
    parameters: [
      {
        id: "categories",
        description: "Odpowiedzi",
        type: FIELD_TYPES.tags,
      },
    ]
  };

  static answer = {
    ...DefaultInfo.answer,
    hasNoCorrectIncorrect: true,
    parameters: [
      {
        id: "category",
        description: "Odpowiedź",
        type: FIELD_TYPES.radio,
        default: 0,
        valuesFrom: {
          section: "question",
          value: "categories"
        },
      },
      ...answerWithCorrectIncorrectFeedback,
    ],
    modules: [
      // "answer_categories"
    ]
  };

  static newQuestion = () => (
    {
      content: "",
      parameters: {},
      answers: [
        this.newAnswer(),
        this.newAnswer(),
        this.newAnswer(),
        this.newAnswer(),
      ]
    }
  );

  static newAnswer = (correct = false) => (
    {
      content: "",
      correct,
      parameters: {}
    }
  );
}

export default ParachutesInfo;